.property-conditions * {
  font-size: 14px;
}
.property-conditions strong {
  color: #333333;
}
.property-conditions .link {
  color: #0077CA;
  font-weight: 600;
}
.property-conditions p {
  margin-bottom: 0;
}
.property-conditions a {
  white-space: nowrap;
  font-weight: 500;
  color: #0077CA;
}
.property-conditions .btn-chevron-right {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0 8px;
}
.property-conditions .btn-chevron-right svg {
  margin-left: 4px;
  height: 12px;
  width: auto;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}